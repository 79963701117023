import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/gaavesd_blog_header.png");
const section_1 = require("../../../assets/img/blogs/gaavesd_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/gaavesd_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/gaavesd_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/gaavesd_blog_img_5.png");
const section_5 = require("../../../assets/img/blogs/gaavesd_blog_img_4.png");
const section_6 = require("../../../assets/img/blogs/gaavesd.gif");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Virtual Service Desk: Automation with Generative AI"
        description="Improve efficiency & satisfaction! Automate virtual service desks with GenAI."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt gaavesd_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to use{" "}
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Generative AI
                    </span>{" "}
                    and Automation to Improve your Virtual Enterprise{" "}
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Employee Service Desk
                    </span>
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to use{" "}
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Generative AI <br />
                    </span>{" "}
                    and Automation to Improve <br /> your Virtual Enterprise
                    <br />
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Employee Service Desk
                    </span>
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Generative AI for customer-facing business functions
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Generative AI and its NLP capabilities for virtual employee
                  service desks
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Challenges of virtual employee service desk Generative AI
                  can solve (use cases)
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Workativ and Generative AI for service desk automation
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How Workativ Improves Virtual Enterprise Employee Service
                  Desk Support with its Conversational AI Platform
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                ChatGPT turns out to be a sudden buzzword, but can we disregard
                its amazing abilities to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  create and automate NLP-based tasks?
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                While everyone observes the surge of ChatGPT, which has been
                quite significant, Generative AI owes its popularity, industry
                interests, and expansion to GPT architecture.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative Pre-trained Transformer
                </span>{" "}
                is a core framework that underpins ChatGPT and helps the
                LLM-powered chat interface unleash the unique potential to
                redefine the existing state of automation in everything that
                takes much more time to perform and is susceptible to errors.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Business leaders across industries are already using some form
                of automation to streamline their day-to-day jobs and optimize
                employee productivity. They want to expand the existing
                automation capabilities of their business processes and enhance
                current business processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Looking back at the state of isolation during COVID-19, it has
                been pragmatic for every business to innovate a way to survive,
                turning work-from-home or telecommuting into a new normal thing.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For today’s business, the challenge isn’t to support the new-age
                necessity for a work-from-home ecosystem but to align with
                strategic needs. To this end, a mechanism must be built to
                facilitate IT or HR support virtually and provide necessary
                employee support to help enable workspace productivity and
                performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                From that standpoint, virtual enterprise employee service desks
                demonstrate capabilities insufficient to automate response and
                service delivery, making finding information difficult and
                impacting productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                  Generative AI redefining natural language processing tasks,
                </a>
                virtual service desks can harness the power of deep learning
                technology to expand the current state of agent efficiency and
                employee support in a unique way.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Generative AI for customer-facing business functions
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Generative AI potential to reduce GPT risks"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                There are{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  risks with Generative AI.
                </a>{" "}
                But that isn’t stopping industry leaders from investing more and
                more in this emergent technology.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the massive potential to transform the existing business
                processes, Generative AI seems mainstream now, forcing leaders
                to adapt to this new industry trend lest they lose many
                prospects in the ‘wait-and-see’ queue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to the Gartner Poll, over
                <a href="https://www.gartner.com/en/newsroom/press-releases/2023-10-03-gartner-poll-finds-55-percent-of-organizations-are-in-piloting-or-production-mode-with-generative-ai">
                  half of organizations have doubled their Generative AI
                  investment in the last ten months.
                </a>{" "}
                Their primary focus is on customer-facing services.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  55% of executive leaders have grown a great interest in
                  Generative AI.
                </li>
                <li className="font-section-normal-text-testimonials">
                  45% of executive leaders surveyed put Generative AI in pilot
                  projects.
                </li>
                <li className="font-section-normal-text-testimonials">
                  10% of the rest have already started using GenAI in
                  production.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                “Executives consider Generative AI for it can drive innovation,
                optimization, and disruption across various business functions,”
                said Karamouzis, Distinguished VP Analyst at Gartner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                47% of businesses are increasing GenAI's investment in
                customer-facing solutions such as software development,
                marketing, customer service, or{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  chatbots.
                </a>
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI drives efficiency for customer-facing functions"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Gartner predicts that customer service or chatbots will see a
                16% investment increase in GenAI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses want to change how customer service, or if we are
                right, employee support is managed. With Generative AI, the
                lever of customer or employee support ─{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  chatbots can gain extended automation capabilities to
                  streamline communications and problem-solving.
                </span>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Generative AI and its NLP capabilities for virtual employee
                service desks
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Generative AI NLP capabilities to automate service desk’s task"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your tryst with ChatGPT so far is quite impressive. You can code
                fast to build an essential website with some necessary menus or
                draw inspiration to create graphics for marketing initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI or GPT interface can be good enough to automate
                effort-intensive tasks by deciphering NLP queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The ability to generate content, especially summarizing lengthy
                articles or notes, translating languages to different languages,
                classifying human responses, and creating new writeups, which
                generative AI is known for, can be extremely useful to augment
                and enhance how virtual employee service desks work.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Summarization
                  </span>{" "}
                  is condensing lengthy articles or comprehensive notes for the
                  agent desks and helping them provide help in no time.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Test Classification
                  </span>{" "}
                  can help with assessment for triage and provide appropriate
                  and accurate answers to employees or customers to enhance
                  problem-solving quickly.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Content generation
                  </span>{" "}
                  using prompts can be critical to reducing the time to craft a
                  new response without taking longer to pull words and craft a
                  personalized message.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Combining all of these potentials within service desks,
                Generative AI can help automate virtual employee support more
                efficiently than what users were supposed to leverage with
                existing virtual employee service desk tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, Generative AI can expand and enhance the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  chatbot automation capability or remove friction from the
                </span>
                <a href="https://workativ.com/conversational-ai-platform/blog/employee-onboarding-knowledge-ai">
                  self-service portal
                </a>{" "}
                from a service desk.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Challenges of virtual employee service desk Generative AI can
                solve (use cases)
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Challenges of virtual employee service desks                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A virtual enterprise employee service desk may have automation
                tools.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, the changing or unique needs require more advanced
                automation capabilities to manage and streamline service desk
                operations or queries for virtual employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In day-to-day employee support, existing levers are enough to
                support known but not unique cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s learn about the existing challenges faced by service desks
                for virtual employees and the Generative AI solutions to these
                problems.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                24/7 real-time support{" "}
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Your traditional service desk can handle a small number of
                tickets and provide support for what a virtual employee needs
                for a common problem.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  But as you scale, the volume of tickets to the service desk
                  also increases. It is a challenge to arrange for a workforce
                  that can be available 24/7.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Outsourced service desk support can be expensive as ticket
                  costs increase massively.
                </li>
                <li className="font-section-normal-text-testimonials">
                  With a few agents, support is scarce during critical needs,
                  increasing MTTR and downtime and causing productivity issues.
                </li>
                <li className="font-section-normal-text-testimonials">
                  In many instances, overutilization of agents causes fatigue,
                  leading to the unavailability of agents and leaving service
                  desks needing help, even with everyday problems.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Even with some automation implemented in the service desk,
                  employee wait is expected to be prolonged. For example,
                  employees tend to be more inclined towards{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    email communications.
                  </span>{" "}
                  Agents can bypass that communication in the sea of requests.
                  Again, if a specific{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    query ID
                  </span>{" "}
                  is generated for a case, employees do not use it and send the
                  exact requests repeatedly, which is untraceable and confusing.
                </li>
              </ul>
              <BlogCta
                ContentCta=" Automate your Virtual Employee Service Desk."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI makes it easy to keep service desk support
                available 24/7 for your employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a large language model working behind the scenes,
                Generative AI can gain massive potential to automate service
                desk NLP tasks, such as responding to natural language queries
                in an automated way.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  Employees become empowered to solve common problems using
                  self-serve portals that use Generative AI, which provides
                  straightforward answers.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  If some queries are unattended, Generative AI can easily fetch
                  history from emails or voice calls to give clear and concise
                  context to the problems and enable him to solve them at scale.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  Employees using the self-service portal can give agents more
                  time to handle critical queries virtually. For example, if an
                  employee has an HR request regarding salary deductions, an
                  employee can communicate via a self-service portal backed by
                  genAI. But, if it is observed that the same problem exists and
                  miscalculations continue, immediate agent help is required. In
                  this scenario, an employee can instantly get agent help and
                  stop the recurring problem.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  Agents are less tired and better at handling queries at scale,
                  resulting in better distribution of agent time and
                  availability for employees.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home mb-3">
                Quick responses to common queries{" "}
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Traditionally, a service desk isn’t flexible enough to receive
                answers to common employee queries. Though it has automated
                response templates for common queries, they can be less
                predictive at specific points and provide repetitive answers.
              </p>
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Generative AI, service desk managers or subject matter
                experts can save time creating common problem FAQs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Each common IT or HR issue requires service desks to create a
                huge volume of FAQs. This is time-consuming and labor-intensive,
                too.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But, Generative AI provides an excellent way to upload any
                length of data in the forms of PDFs, documents, images, Excel,
                or just about anything to train the large language models
                underpinning genAI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                The data can be comprehensive, containing IT or HR scenarios and
                the history of cases an organization handled. As a result, GenAI
                no longer needs to depend on keyword-based search or FAQ-based
                pre-defined responses. Instead, the technology can apply
                semantic search to find a match for NLP queries across documents
                and produce coherent responses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Using a genAI-based service desk for common and related queries,
                employees can expect a resolution and reduce downtime in an
                ongoing conversation.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Ticket triage and escalation for ITOps
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The problem is such for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  a service desk
                </a>{" "}
                that it takes time for agents to triage a ticket and escalate it
                to the right team.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                One of many reasons is that a logged report to the service desk
                contains a vague explanation. Someone sending an incident note
                may need more knowledge to describe the incident and provide
                proper insights.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, they pose a risk to the service desk when triaging
                incident notes, categorizing tickets based on urgency and
                escalating to the right team.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With that, if an expert incident manager is out of the office,
                it is tough to get expert help in real time and comprehend the
                incident message, leading to a delayed incident triage and
                routing of the ticket to the right team member at the service
                desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, an employee’s desktop crashes down, and he writes
                an incident note to the service desk. If the note is unclear,
                the service desk can have difficulty routing the ticket to the
                right team among the desktop, application, and network teams.
              </p>
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI eliminates the need for expert help to decipher
                what an incident message contains for the service desk. Using
                its pre-trained language model, GenAI can draw existing
                knowledge from large language models and apply semantics and
                context to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  classify text and categorize incidents.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a user sends out a message that reads,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ‘screen jumps.’
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The word is not an appropriate technical term to state a
                problem. If messages are unclear, your team needs to communicate
                with the requester via email, voice, or whatever to clarify.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI-powered service desk helps classify the message and
                understand which team can handle the issue appropriately.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Text classification with Generative AI for service desks    "
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                As Generative AI offers insights into various types of
                screen-related problems, the service desk can easily classify
                that the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  desktop support team
                </span>{" "}
                is better at handling these issues than the networking or
                application team.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is an impoverished option to apply classification and find
                escalation suggestions for immediate help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, if you have a custom classification model, you can have
                a more straightforward and automated option to perform ticket
                triage. To do this, you need specific triage data to train your
                model.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Agent performance
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a changing shift for agents, the same agent may be
                unavailable on a service requester call. Employees become
                frustrated when they need to repeat the case history.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Finding a resolution for an issue takes time and causes a
                productivity slump for the agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Another challenge with
                <a href="https://workativ.com/conversational-ai-platform/blog/boost-agent-efficiency-shared-inbox-integration">
                  agent performance
                </a>{" "}
                is that they must constantly type messages or provide
                suggestions on a call.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If an amateur agent handles a particular case, suppose a login
                issue with a digital attendance system, he may need expert help
                to provide the right suggestions and resolutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In both scenarios, an agent needs insights to help the requester
                out of the problem. But, if knowledge is not available,
                resolution will not be timely.
              </p>
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Generative AI automates response generation                 "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the ability to generate unique content with prompts and
                offer insights into the requester's sentiments, Generative AI
                can immensely help populate answers for an employee's queries
                even if the agent dealing with the case is unavailable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, genAI makes it easy to find the history of previous cases,
                understand patterns of resolutions provided, and enable an agent
                to provide appropriate suggestions in real-time and solve
                problems instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, for an agent who is not adept enough to write
                grammatically correct messages or offer suggestions on voice
                calls, the Generative AI service desk can easily enable it to
                craft contextual and meaningful messages to offer autonomous
                help.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Frictionless self-service
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Though automation is applied to service desk operations,
                automation is not fully implemented to offer frictionless help
                in the self-service portal through a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  virtual enterprise service desk.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say password reset is automated. However, an employee
                experiences constant access issues with a web-based email
                system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A virtual employee finds working challenging if he faces login
                issues, even if the password is reset. A perfect resolution is
                to connect with an agent in real-time. But if an agent is not
                available, wait time increases.
              </p>

              <h4 className="font-section-normal-text-medium">Solutions: </h4>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Large language models can trained with existing case history,
                unique cases, and even resolution data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of that, Generative AI constantly learns from experiences
                and builds a predictive model to suggest a resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say, automated password reset is done successfully. But, a login
                access issue persists.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Suppose a service desk is powered by a Generative AI chatbot
                with large language models and a massive dataset of documents
                with related issue-resolving tips, such as account permissions,
                account lockout, browser cache or cookies, etc. In that case, an
                employee can get summarized answers and resolve the password
                issue autonomously.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Automation of repetitive tasks
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A service desk regularly performs repetitive tasks such as
                password resets, account unlocks, adding users to a group, and
                onboarding or offboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Virtual employees can become frustrated if they get resolutions
                quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Agents and HR executives remain busy with more strategic work
                manually, so they can rarely offer more personalized help, which
                can cause friction in the employee experience.
              </p>

              <h4 className="font-section-normal-text-medium">Solutions: </h4>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-hr-support">
                  Generative AI can extend the automation capability
                </a>{" "}
                of existing service desk tasks through NLP-based query-resolving
                solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Repetitive and mundane tasks such as password resets, PTO
                inquiries, tax inquiries,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  IT support help,
                </span>{" "}
                etc., are automated efficiently without the need to guide the
                users with article notes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Instead, Generative AI can offer users summarized and
                straightforward answers and help them resolve issues
                autonomously in real-time.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ and Generative AI for service desk automation
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To help enterprise leaders alleviate virtual employee support
                challenges,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                brings to the forefront the best of Generative AI within its{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI platform
                </a>{" "}
                that harnesses the power of a large language model and allows
                them to leverage this exciting technology cost-effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ has introduced{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                for service desk operations to transform the virtual employee
                experience through more meaningful and personalized replies to
                employee questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI allows users to upload massive datasets to the
                large language model platform embedded inside the Workativ
                conversational AI platform in any form and train the model with
                little effort, like foundation models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, users need not spend time creating a large set of
                FAQ-based templates. Instead, they can leverage the benefits of
                custom responses through Knowledge AI search integration inside
                a conversational AI platform and quickly generate the most
                accurate, relevant, and coherent response for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition, Workativ also uses Hybrid NLU for its chatbot
                search with ranker and resolver endpoints to derive the most
                relevant information from the knowledge base and improve search
                performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When searching for information across Wikipedia, the search has
                random results that may contain something other than what a user
                is looking for. Hybrid NLU in Workativ can eliminate this
                challenge and surface relevant answers using ranker or resolver
                endpoints.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How Workativ Improves Virtual Enterprise Employee Service Desk
                Support with its Conversational AI Platform
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                When using a large language model for a service desk, users can
                get generic responses that are not useful for specific cases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Workativ’s Knowledge AI
                </a>{" "}
                allows you to surface{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  domain-specific responses
                </span>{" "}
                more straightforwardly, helping your virtual employees solve
                problems effectively and efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                On the other hand, Hybrid NLU provides accuracy in responses
                that remove confusion and solve problems steadily.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As a result, virtual employees remain calm and less stressed
                when a sudden downtime happens because they know they can
                leverage Knowledge AI and get autonomous help, reducing friction
                and MTTR to help them get back to work in less time.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                With{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                redefining virtual enterprise employee service desks, leaders or
                IT managers can dedicate more time to strategic responsibilities
                and ramp up employee productivity that expedites growth and
                value.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                GoTo has more than 50+ applications in its environment, like
                Adobe, VMware, SolarWinds ITSM, Office 365, SharePoint,
                NetSuite, Monday.com, Slack, and others.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                  {" "}
                  Workativ helped the GoTo team
                </a>{" "}
                auto-resolve repetitive IT queries, issues, and requests and
                improved the experience for over 3,500+ employees using
                Knowledge AI in the conversational AI platform.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                To learn how to implement a cost-effective Generative AI service
                desk project for your virtual employees,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                1. What problems does a traditional employee service desk pose
                to IT?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Traditional employee service desks use keyword-based knowledge
                searches. When question volume increases, or unique questions
                are asked, businesses can face scalability issues.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                2. How can Generative AI improve virtual employee service desks?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI provides extended automation advantages to
                streamline workflows and make information search easy and fast
                for employees to resolve issues. This helps in increasing
                efficiency and reducing downtime.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                3. What are the examples of routine tasks that Generative AI can
                automate for your virtual service desks?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Virtual employee service desks receive routine requests such as
                password resets, software installs, account unlocks, etc.
                Generative AI uses deep learning algorithms to automate these
                tasks by extending the automation limit.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                4. What are the benefits of Generative AI for your service
                desks?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI improves productivity and efficiency for service
                desk operations. Employees solve routine problems fast, agents
                can address unique cases efficiently, and service desk see fewer
                tickets, which apparently improve cost efficiency for
                businesses.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Generative AI for customer-facing business functions
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Generative AI and its NLP capabilities for virtual
                    employee service desks
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Challenges of virtual employee service desk Generative AI
                    can solve (use cases)
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Workativ and Generative AI for service desk automation
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How Workativ Improves Virtual Enterprise Employee Service
                    Desk Support with its Conversational AI Platform
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                ChatGPT turns out to be a sudden buzzword, but can we disregard
                its amazing abilities to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  create and automate NLP-based tasks?
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                While everyone observes the surge of ChatGPT, which has been
                quite significant, Generative AI owes its popularity, industry
                interests, and expansion to GPT architecture.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative Pre-trained Transformer
                </span>{" "}
                is a core framework that underpins ChatGPT and helps the
                LLM-powered chat interface unleash the unique potential to
                redefine the existing state of automation in everything that
                takes much more time to perform and is susceptible to errors.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Business leaders across industries are already using some form
                of automation to streamline their day-to-day jobs and optimize
                employee productivity. They want to expand the existing
                automation capabilities of their business processes and enhance
                current business processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Looking back at the state of isolation during COVID-19, it has
                been pragmatic for every business to innovate a way to survive,
                turning work-from-home or telecommuting into a new normal thing.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For today’s business, the challenge isn’t to support the new-age
                necessity for a work-from-home ecosystem but to align with
                strategic needs. To this end, a mechanism must be built to
                facilitate IT or HR support virtually and provide necessary
                employee support to help enable workspace productivity and
                performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                From that standpoint, virtual enterprise employee service desks
                demonstrate capabilities insufficient to automate response and
                service delivery, making finding information difficult and
                impacting productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                  Generative AI redefining natural language processing tasks,
                </a>
                virtual service desks can harness the power of deep learning
                technology to expand the current state of agent efficiency and
                employee support in a unique way.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Generative AI for customer-facing business functions
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Generative AI potential to reduce GPT risks"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                There are{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  risks with Generative AI.
                </a>{" "}
                But that isn’t stopping industry leaders from investing more and
                more in this emergent technology.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the massive potential to transform the existing business
                processes, Generative AI seems mainstream now, forcing leaders
                to adapt to this new industry trend lest they lose many
                prospects in the ‘wait-and-see’ queue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to the Gartner Poll, over
                <a href="https://www.gartner.com/en/newsroom/press-releases/2023-10-03-gartner-poll-finds-55-percent-of-organizations-are-in-piloting-or-production-mode-with-generative-ai">
                  half of organizations have doubled their Generative AI
                  investment in the last ten months.
                </a>{" "}
                Their primary focus is on customer-facing services.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  55% of executive leaders have grown a great interest in
                  Generative AI.
                </li>
                <li className="font-section-normal-text-testimonials">
                  45% of executive leaders surveyed put Generative AI in pilot
                  projects.
                </li>
                <li className="font-section-normal-text-testimonials">
                  10% of the rest have already started using GenAI in
                  production.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                “Executives consider Generative AI for it can drive innovation,
                optimization, and disruption across various business functions,”
                said Karamouzis, Distinguished VP Analyst at Gartner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                47% of businesses are increasing GenAI's investment in
                customer-facing solutions such as software development,
                marketing, customer service, or{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  chatbots.
                </a>
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI drives efficiency for customer-facing functions"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Gartner predicts that customer service or chatbots will see a
                16% investment increase in GenAI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses want to change how customer service, or if we are
                right, employee support is managed. With Generative AI, the
                lever of customer or employee support ─{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  chatbots can gain extended automation capabilities to
                  streamline communications and problem-solving.
                </span>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Generative AI and its NLP capabilities for virtual employee
                service desks
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Generative AI NLP capabilities to automate service desk’s task"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your tryst with ChatGPT so far is quite impressive. You can code
                fast to build an essential website with some necessary menus or
                draw inspiration to create graphics for marketing initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI or GPT interface can be good enough to automate
                effort-intensive tasks by deciphering NLP queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The ability to generate content, especially summarizing lengthy
                articles or notes, translating languages to different languages,
                classifying human responses, and creating new writeups, which
                generative AI is known for, can be extremely useful to augment
                and enhance how virtual employee service desks work.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Summarization
                  </span>{" "}
                  is condensing lengthy articles or comprehensive notes for the
                  agent desks and helping them provide help in no time.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Test Classification
                  </span>{" "}
                  can help with assessment for triage and provide appropriate
                  and accurate answers to employees or customers to enhance
                  problem-solving quickly.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Content generation
                  </span>{" "}
                  using prompts can be critical to reducing the time to craft a
                  new response without taking longer to pull words and craft a
                  personalized message.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Combining all of these potentials within service desks,
                Generative AI can help automate virtual employee support more
                efficiently than what users were supposed to leverage with
                existing virtual employee service desk tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, Generative AI can expand and enhance the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  chatbot automation capability or remove friction from the
                </span>
                <a href="https://workativ.com/conversational-ai-platform/blog/employee-onboarding-knowledge-ai">
                  self-service portal
                </a>{" "}
                from a service desk.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Challenges of virtual employee service desk Generative AI can
                solve (use cases)
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Challenges of virtual employee service desks                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A virtual enterprise employee service desk may have automation
                tools.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, the changing or unique needs require more advanced
                automation capabilities to manage and streamline service desk
                operations or queries for virtual employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In day-to-day employee support, existing levers are enough to
                support known but not unique cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s learn about the existing challenges faced by service desks
                for virtual employees and the Generative AI solutions to these
                problems.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                24/7 real-time support{" "}
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Your traditional service desk can handle a small number of
                tickets and provide support for what a virtual employee needs
                for a common problem.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  But as you scale, the volume of tickets to the service desk
                  also increases. It is a challenge to arrange for a workforce
                  that can be available 24/7.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Outsourced service desk support can be expensive as ticket
                  costs increase massively.
                </li>
                <li className="font-section-normal-text-testimonials">
                  With a few agents, support is scarce during critical needs,
                  increasing MTTR and downtime and causing productivity issues.
                </li>
                <li className="font-section-normal-text-testimonials">
                  In many instances, overutilization of agents causes fatigue,
                  leading to the unavailability of agents and leaving service
                  desks needing help, even with everyday problems.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Even with some automation implemented in the service desk,
                  employee wait is expected to be prolonged. For example,
                  employees tend to be more inclined towards{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    email communications.
                  </span>{" "}
                  Agents can bypass that communication in the sea of requests.
                  Again, if a specific{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    query ID
                  </span>{" "}
                  is generated for a case, employees do not use it and send the
                  exact requests repeatedly, which is untraceable and confusing.
                </li>
              </ul>
              <BlogCta
                ContentCta=" Automate your Virtual Employee Service Desk."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI makes it easy to keep service desk support
                available 24/7 for your employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a large language model working behind the scenes,
                Generative AI can gain massive potential to automate service
                desk NLP tasks, such as responding to natural language queries
                in an automated way.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  Employees become empowered to solve common problems using
                  self-serve portals that use Generative AI, which provides
                  straightforward answers.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  If some queries are unattended, Generative AI can easily fetch
                  history from emails or voice calls to give clear and concise
                  context to the problems and enable him to solve them at scale.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  Employees using the self-service portal can give agents more
                  time to handle critical queries virtually. For example, if an
                  employee has an HR request regarding salary deductions, an
                  employee can communicate via a self-service portal backed by
                  genAI. But, if it is observed that the same problem exists and
                  miscalculations continue, immediate agent help is required. In
                  this scenario, an employee can instantly get agent help and
                  stop the recurring problem.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  Agents are less tired and better at handling queries at scale,
                  resulting in better distribution of agent time and
                  availability for employees.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home mb-3">
                Quick responses to common queries{" "}
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Traditionally, a service desk isn’t flexible enough to receive
                answers to common employee queries. Though it has automated
                response templates for common queries, they can be less
                predictive at specific points and provide repetitive answers.
              </p>
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Generative AI, service desk managers or subject matter
                experts can save time creating common problem FAQs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Each common IT or HR issue requires service desks to create a
                huge volume of FAQs. This is time-consuming and labor-intensive,
                too.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But, Generative AI provides an excellent way to upload any
                length of data in the forms of PDFs, documents, images, Excel,
                or just about anything to train the large language models
                underpinning genAI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                The data can be comprehensive, containing IT or HR scenarios and
                the history of cases an organization handled. As a result, GenAI
                no longer needs to depend on keyword-based search or FAQ-based
                pre-defined responses. Instead, the technology can apply
                semantic search to find a match for NLP queries across documents
                and produce coherent responses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Using a genAI-based service desk for common and related queries,
                employees can expect a resolution and reduce downtime in an
                ongoing conversation.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Ticket triage and escalation for ITOps
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The problem is such for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  a service desk
                </a>{" "}
                that it takes time for agents to triage a ticket and escalate it
                to the right team.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                One of many reasons is that a logged report to the service desk
                contains a vague explanation. Someone sending an incident note
                may need more knowledge to describe the incident and provide
                proper insights.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, they pose a risk to the service desk when triaging
                incident notes, categorizing tickets based on urgency and
                escalating to the right team.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With that, if an expert incident manager is out of the office,
                it is tough to get expert help in real time and comprehend the
                incident message, leading to a delayed incident triage and
                routing of the ticket to the right team member at the service
                desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, an employee’s desktop crashes down, and he writes
                an incident note to the service desk. If the note is unclear,
                the service desk can have difficulty routing the ticket to the
                right team among the desktop, application, and network teams.
              </p>
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI eliminates the need for expert help to decipher
                what an incident message contains for the service desk. Using
                its pre-trained language model, GenAI can draw existing
                knowledge from large language models and apply semantics and
                context to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  classify text and categorize incidents.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a user sends out a message that reads,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ‘screen jumps.’
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The word is not an appropriate technical term to state a
                problem. If messages are unclear, your team needs to communicate
                with the requester via email, voice, or whatever to clarify.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI-powered service desk helps classify the message and
                understand which team can handle the issue appropriately.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Text classification with Generative AI for service desks    "
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                As Generative AI offers insights into various types of
                screen-related problems, the service desk can easily classify
                that the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  desktop support team
                </span>{" "}
                is better at handling these issues than the networking or
                application team.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is an impoverished option to apply classification and find
                escalation suggestions for immediate help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, if you have a custom classification model, you can have
                a more straightforward and automated option to perform ticket
                triage. To do this, you need specific triage data to train your
                model.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Agent performance
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a changing shift for agents, the same agent may be
                unavailable on a service requester call. Employees become
                frustrated when they need to repeat the case history.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Finding a resolution for an issue takes time and causes a
                productivity slump for the agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Another challenge with
                <a href="https://workativ.com/conversational-ai-platform/blog/boost-agent-efficiency-shared-inbox-integration">
                  agent performance
                </a>{" "}
                is that they must constantly type messages or provide
                suggestions on a call.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If an amateur agent handles a particular case, suppose a login
                issue with a digital attendance system, he may need expert help
                to provide the right suggestions and resolutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In both scenarios, an agent needs insights to help the requester
                out of the problem. But, if knowledge is not available,
                resolution will not be timely.
              </p>
              <h4 className="font-section-normal-text-medium">Solutions: </h4>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Generative AI automates response generation                 "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the ability to generate unique content with prompts and
                offer insights into the requester's sentiments, Generative AI
                can immensely help populate answers for an employee's queries
                even if the agent dealing with the case is unavailable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, genAI makes it easy to find the history of previous cases,
                understand patterns of resolutions provided, and enable an agent
                to provide appropriate suggestions in real-time and solve
                problems instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, for an agent who is not adept enough to write
                grammatically correct messages or offer suggestions on voice
                calls, the Generative AI service desk can easily enable it to
                craft contextual and meaningful messages to offer autonomous
                help.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Frictionless self-service
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Though automation is applied to service desk operations,
                automation is not fully implemented to offer frictionless help
                in the self-service portal through a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  virtual enterprise service desk.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say password reset is automated. However, an employee
                experiences constant access issues with a web-based email
                system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A virtual employee finds working challenging if he faces login
                issues, even if the password is reset. A perfect resolution is
                to connect with an agent in real-time. But if an agent is not
                available, wait time increases.
              </p>

              <h4 className="font-section-normal-text-medium">Solutions: </h4>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Large language models can trained with existing case history,
                unique cases, and even resolution data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of that, Generative AI constantly learns from experiences
                and builds a predictive model to suggest a resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say, automated password reset is done successfully. But, a login
                access issue persists.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Suppose a service desk is powered by a Generative AI chatbot
                with large language models and a massive dataset of documents
                with related issue-resolving tips, such as account permissions,
                account lockout, browser cache or cookies, etc. In that case, an
                employee can get summarized answers and resolve the password
                issue autonomously.
              </p>
              <h3 className="font-section-sub-header-small-home mb-3">
                Automation of repetitive tasks
              </h3>
              <h4 className="font-section-normal-text-medium">Problem:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A service desk regularly performs repetitive tasks such as
                password resets, account unlocks, adding users to a group, and
                onboarding or offboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Virtual employees can become frustrated if they get resolutions
                quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Agents and HR executives remain busy with more strategic work
                manually, so they can rarely offer more personalized help, which
                can cause friction in the employee experience.
              </p>

              <h4 className="font-section-normal-text-medium">Solutions: </h4>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-hr-support">
                  Generative AI can extend the automation capability
                </a>{" "}
                of existing service desk tasks through NLP-based query-resolving
                solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Repetitive and mundane tasks such as password resets, PTO
                inquiries, tax inquiries,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  IT support help,
                </span>{" "}
                etc., are automated efficiently without the need to guide the
                users with article notes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Instead, Generative AI can offer users summarized and
                straightforward answers and help them resolve issues
                autonomously in real-time.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ and Generative AI for service desk automation
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To help enterprise leaders alleviate virtual employee support
                challenges,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                brings to the forefront the best of Generative AI within its{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI platform
                </a>{" "}
                that harnesses the power of a large language model and allows
                them to leverage this exciting technology cost-effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ has introduced{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                for service desk operations to transform the virtual employee
                experience through more meaningful and personalized replies to
                employee questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI allows users to upload massive datasets to the
                large language model platform embedded inside the Workativ
                conversational AI platform in any form and train the model with
                little effort, like foundation models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, users need not spend time creating a large set of
                FAQ-based templates. Instead, they can leverage the benefits of
                custom responses through Knowledge AI search integration inside
                a conversational AI platform and quickly generate the most
                accurate, relevant, and coherent response for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition, Workativ also uses Hybrid NLU for its chatbot
                search with ranker and resolver endpoints to derive the most
                relevant information from the knowledge base and improve search
                performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When searching for information across Wikipedia, the search has
                random results that may contain something other than what a user
                is looking for. Hybrid NLU in Workativ can eliminate this
                challenge and surface relevant answers using ranker or resolver
                endpoints.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How Workativ Improves Virtual Enterprise Employee Service Desk
                Support with its Conversational AI Platform
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                When using a large language model for a service desk, users can
                get generic responses that are not useful for specific cases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Workativ’s Knowledge AI
                </a>{" "}
                allows you to surface{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  domain-specific responses
                </span>{" "}
                more straightforwardly, helping your virtual employees solve
                problems effectively and efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                On the other hand, Hybrid NLU provides accuracy in responses
                that remove confusion and solve problems steadily.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As a result, virtual employees remain calm and less stressed
                when a sudden downtime happens because they know they can
                leverage Knowledge AI and get autonomous help, reducing friction
                and MTTR to help them get back to work in less time.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                With{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                redefining virtual enterprise employee service desks, leaders or
                IT managers can dedicate more time to strategic responsibilities
                and ramp up employee productivity that expedites growth and
                value.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                GoTo has more than 50+ applications in its environment, like
                Adobe, VMware, SolarWinds ITSM, Office 365, SharePoint,
                NetSuite, Monday.com, Slack, and others.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                  {" "}
                  Workativ helped the GoTo team
                </a>{" "}
                auto-resolve repetitive IT queries, issues, and requests and
                improved the experience for over 3,500+ employees using
                Knowledge AI in the conversational AI platform.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                To learn how to implement a cost-effective Generative AI service
                desk project for your virtual employees,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                1. What problems does a traditional employee service desk pose
                to IT?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Traditional employee service desks use keyword-based knowledge
                searches. When question volume increases, or unique questions
                are asked, businesses can face scalability issues.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                2. How can Generative AI improve virtual employee service desks?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI provides extended automation advantages to
                streamline workflows and make information search easy and fast
                for employees to resolve issues. This helps in increasing
                efficiency and reducing downtime.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                3. What are the examples of routine tasks that Generative AI can
                automate for your virtual service desks?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Virtual employee service desks receive routine requests such as
                password resets, software installs, account unlocks, etc.
                Generative AI uses deep learning algorithms to automate these
                tasks by extending the automation limit.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                4. What are the benefits of Generative AI for your service
                desks?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI improves productivity and efficiency for service
                desk operations. Employees solve routine problems fast, agents
                can address unique cases efficiently, and service desk see fewer
                tickets, which apparently improve cost efficiency for
                businesses.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk"
              className="font-section-normal-text-testimonials"
            >
              Reactive to Proactive: Leveraging Generative AI for Smarter
              Employee Service Desks
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-save-itsm-cost"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Top 5 Ways CXOs Can Save ITSM Cost Using Generative AI
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
